<template>
  <section class="aba-formulario-pessoal">
    <div class="pa-0 mt-4">
      <v-row v-if="empresa.socios.qsa.length > 0">
        <v-col sm="12" md="12" cols="12" class="padding-bottom-half">
          <v-list two-line subheader>
            <v-subheader>Sócios</v-subheader>
            <v-list-item
              v-for="(socio, i) in empresa.socios.qsa"
              :key="i"
            >
              <v-list-item-content>
                <v-list-item-title v-text="socio.nome"></v-list-item-title>
                <v-list-item-subtitle v-text="socio.qual"></v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-col>
      </v-row>
    </div>
  </section>
</template>

<script>
export default {
  name: "pessoal",
  data: () => ({
    objetoForm: {},
    socios: {},
  }),
  computed: {
    empresa: {
      get() {
        return this.$store.getters["empresas/getEmpresaUsuarios"];
      },
      set(value) {
        this.$store.dispatch("empresas/setEmpresaUsuarios", value);
      },
    },
  }
};
</script>

<style>
</style>