import { VCol } from 'vuetify/lib/components/VGrid';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSubheader } from 'vuetify/lib/components/VSubheader';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"aba-formulario-pessoal"},[_c('div',{staticClass:"pa-0 mt-4"},[(_vm.empresa.socios.qsa.length > 0)?_c(VRow,[_c(VCol,{staticClass:"padding-bottom-half",attrs:{"sm":"12","md":"12","cols":"12"}},[_c(VList,{attrs:{"two-line":"","subheader":""}},[_c(VSubheader,[_vm._v("Sócios")]),_vm._l((_vm.empresa.socios.qsa),function(socio,i){return _c(VListItem,{key:i},[_c(VListItemContent,[_c(VListItemTitle,{domProps:{"textContent":_vm._s(socio.nome)}}),_c(VListItemSubtitle,{domProps:{"textContent":_vm._s(socio.qual)}})],1)],1)})],2)],1)],1):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }